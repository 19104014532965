/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import {Subscription} from 'rxjs/Rx';

// angular
import {Component,ViewEncapsulation} from '@angular/core';

// mn
import {MnUnsubscribe} from "@mn/core"
import {MnHelpTopic, MnHelpTopicService} from "@mn/help"
import {MnMmdb} from "@mn/mmdb";

//
//import {MnMmdb} from './MnMmdb';

@Component({
    selector: 'mn-model-help',
    templateUrl: './MnModelHelp.html',
    styleUrls: ['./MnModelHelp.css'],
    //encapsulation: ViewEncapsulation.None
})
@MnUnsubscribe()
export class MnModelHelp {

    mOnTopicChange:Subscription;
    mMeta:any =  {};

    constructor(private mService:MnHelpTopicService, private mMmdb:MnMmdb) {
        //this.tree = this.mMmdb.getModelTree().toPromise();
        this.mOnTopicChange = this.mService.onTopic(topic => this.onTopicChange(topic));
    }

    private onTopicChange(topic:MnHelpTopic) {
        console.log("topic change",topic);
        this.mMmdb.getModelData(topic.data.id).subscribe(meta => this.updateMeta(meta));
    }

    private updateMeta(meta) {
        this.mMeta = meta;
        //console.log("--- mMeta ---");
        //console.log(this.mMeta);
    }

    ngOnDestroy() {

    }
}
