/**
 * Created by joerg on 5/3/17.
 */

// angular
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// uirouter
import { UIRouterModule } from '@uirouter/angular';

// mn
import { MnCoreModule } from '@mn/core';
import { MnRouteModule } from '@mn/route';
import { MnMmdbModule, MnMmdb } from '@mn/mmdb';
import { MnHelpModule, MnHelpService, MnHelpTopic } from '@mn/help';

//
import { MnModelHelp } from './MnModelHelp';

let help_registered:boolean = false;
let directives = [MnModelHelp];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UIRouterModule.forChild(),
        MnCoreModule,
        MnRouteModule,
        MnMmdbModule,
        MnHelpModule
    ],
    exports: [],
    providers: [MnMmdb],
    declarations: directives,
    entryComponents: directives
})
export class MnMmhelpModule {
    constructor(private mMmdb:MnMmdb,private mHelp:MnHelpService) {
        console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> mmdb",mMmdb);
        console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> help",mHelp);


    }
    public initMnModule() {
        console.log("X>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> mmdb");
        console.log("X>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> help");

        if (help_registered) {
            return true;
        }
        else {
            help_registered = true;
            return this.mMmdb.getModelTree().map((tree) => {
                console.log("Registering help for Model Metainformation Database",tree);

                let help_topics:MnHelpTopic = {
                    component: {},
                    title: "Level 0",
                    data: {},
                    topics: {
                        calc: {
                            component: {},
                            title: "Predictions",
                            data: {},
                            topics: {},
                            lazy: { lib: "@mn/mmhelp", mod: "MnMmhelpModule" }
                        }
                    }
                };

                this.registerHelp(help_topics.topics.calc,tree);
                this.mHelp.registerTopic(help_topics);
                return help_topics;
            }).toPromise();
        }
    }

    private registerHelp(parent_topic, tree) {
        for (var i = 0, l = tree.length; i < l; i++) {
            var child = tree[i];

            if (child.label && child.title) {
                var child_topic_key = child.label; // replace by title
                var child_topic_key = child_topic_key.replace(/<p>|<\/p>/gi, "");
                var child_topic_title = child_topic_key;
                var child_topic_key = child_topic_key.trim().replace(/ |#/gi, "_");
                var child_topic_leaf = {
                    component: {lib: '@mn/mmhelp', mod: 'MnMmhelpModule', comp: 'MnModelHelp'},
                    title: child_topic_title,
                    data: child,
                    topics: {}
                };
                parent_topic.topics[child_topic_key] = child_topic_leaf;
            } else {
                var child_topic_key = child.name;
                var child_topic_key = child_topic_key.replace(/<p>|<\/p>/gi, "");
                var child_topic_title = child_topic_key;
                var child_topic_key = child_topic_key.trim().replace(/ |#/gi, "_");
                var child_topic = {
                    //component: {lib: '@mn/mmhelp', mod: 'MnMmhelpModule', comp: 'MnModelHelp'},
                    title: child_topic_title,
                    data: {},
                    topics: {}
                };
                parent_topic.topics[child_topic_key] = child_topic;
                if (child.children) {
                    this.registerHelp(child_topic,child.children);
                }
            }
        }
    }

    /*static forRoot(): ModuleWithProviders {
        return {
            ngModule: MnHelpModule,
            providers: [MnHelpService]
        };
    }*/
}

export class mn_mmhelp {
    static configure():any {
        return [
            MnMmhelpModule
            //MnHelpModule.forRoot()
        ];
    }
}